import { useRef } from 'react'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  useForm,
  FormProvider,
  useToast,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import InputBottleshop from '../InputBottleshop'
import StoreEntity from '@ts/core/src/modules/store/store.entity'
import offers from '../../pages/account/offers'
import { OfferEntity } from '@thirsty-camel/hump-club/src/modules/offer/offer.entity'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'

const ModalRedeemOfferInStore: React.FC<ModalProps & { offer: OfferEntity }> = ({
  offer,
  isVisible,
  onClose,
}) => {
  const toast = useToast()
  const form = useForm<{ store: StoreEntity }>()
  const selectedStore = useStore(s => s.store.selectedStore)
  const { isFetching } = useStore(s => s.offers)
  const fetchOffers = useActions(actions => actions.offers.fetchOffers)
  const redeemOfferInStore = useActions(actions => actions.offers.redeemOfferInStore)
  const initialFocusRef = useRef(null)

  const handleSelectBottleshop = async () => {
    const store = form.getValues().store

    /* Make a query to the backend to redeem this offer in store */
    try {
      await redeemOfferInStore({ id: offer.id, store: store.humpClubStoreCode || store.slug })
      toast({
        title: 'Offer redeemed',
        description: 'This offer has been redeemed',
        status: 'success',
      })
      fetchOffers()
    } catch (e) {
      toast({
        title: 'Error redeeming offer in-store',
        description: getNiceErrorMessage(e),
        status: 'error',
        isClosable: true,
      })
    }

    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="TO REDEEM THIS OFFER"
    >
      <Stack py={2} spacing={5}>
        <Text>
          Head straight instore and quote your mobile number at the till. To see if your nearest
          venue offers this, check if they have the loyalty rewards icon in the find a store
          section.
        </Text>
        <Text>
          If not select the store below you would like to redeem the offer. Once you press ‘redeem’
          the offer is valid for 6 hours only, make sure you’re ready! Only stores the offer is
          available will be available to select.
        </Text>
        <FormProvider {...form}>
          <InputBottleshop
            label="Select store"
            handleSelectBottleshop={handleSelectBottleshop}
            isLoading={isFetching}
            limitedTo={offer.store ? [offer.store.humpClubStoreCode] : null}
          />
        </FormProvider>
      </Stack>
    </Modal>
  )
}

export default ModalRedeemOfferInStore
