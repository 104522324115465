import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  SearchInput,
  Stack,
  Text,
  TextInput,
  useFormContext,
  useStoreSearch,
  useWatch,
} from '@thirstycamel/ui'
import type StoreEntity from '@ts/core/src/modules/store/store.entity'
import { useEffect } from 'react'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import Input from '../Input/Input'

export const InputBottleshop = ({
  handleSelectBottleshop,
  label = 'ADD BOTTLESHOP',
  defaultValue,
  isLoading,
  limitedTo = [],
  limitedToStates,
}: {
  handleSelectBottleshop: () => void
  label?: string
  defaultValue?: StoreEntity
  isLoading?: boolean
  limitedTo?: string[]
  limitedToStates?: string[]
}) => {
  const form = useFormContext()
  const search = useStoreSearch<StoreEntity>({
    limit: 5,
    humpClubIds: limitedTo ? limitedTo.join(',') : '',
    ...(limitedToStates ? { state: limitedToStates.join(',') } : {}),
  })
  const selectedStore: StoreEntity = useWatch({ control: form.control, name: 'store' })

  useEffect(() => {
    if (defaultValue) {
      form.setValue('store', defaultValue)
      form.setValue(
        'store__search',
        `${defaultValue.name} ${defaultValue.region}, ${defaultValue.postcode}`,
      )
    }
  }, [defaultValue])

  return (
    <Stack maxW="25rem">
      <Stack spacing={5}>
        <Input as={TextInput} type="hidden" name="store" isRequired />
        <Input
          as={SearchInput}
          name="store__search"
          label={label}
          placeholder="Enter suburb or postcode..."
          onChangeValue={search.setSearchValue}
          isSearching={search.isFetching}
          autoComplete="off"
          appearsRequired
          error={form.errors?.store}
        />

        <Button
          variantColor="pink"
          isLoading={isLoading}
          isDisabled={!selectedStore}
          onClick={handleSelectBottleshop}
        >
          {label.toUpperCase()}
        </Button>
      </Stack>

      {(search.searchValue?.length > 0 || search.isFetching || search.error) && (
        <Box border="1px" borderColor="gray.200" bg="gray.50">
          <Stack isInline align="center" justify="space-between" mx={3} my={2}>
            <Text fontSize="sm">Results:</Text>
          </Stack>

          <Divider />

          {search.resolvedData?.items?.length > 0 ? (
            <Flex direction="column" maxHeight="225px" overflowY="scroll">
              {search.resolvedData?.items.map((result, index) => (
                <Button
                  variant="unstyled"
                  onClick={() => {
                    search.setSearchValue('')
                    form.setValue('store', result)
                    form.setValue(
                      'store__search',
                      `${result.name} ${result.region}, ${result.postcode}`,
                    )
                  }}
                  textAlign="left"
                  p={5}
                  borderTop={index > 0 ? '1px solid' : 0}
                  borderColor="gray.200"
                  justifyContent="space-between"
                >
                  <Stack spacing={1}>
                    <Heading fontSize="xs">
                      {result.name} {result.region}, {result.postcode}
                    </Heading>
                    <Text color="blackAlpha.700" fontSize="xs">
                      {result.address}
                    </Text>
                  </Stack>
                  <Icon name="chevron-right" fontSize="2xl" ml={2} />
                </Button>
              ))}
            </Flex>
          ) : search.isFetching ? (
            <Text m={5}>Loading...</Text>
          ) : search.resolvedData?.items?.length === 0 ? (
            <Text m={5}>No results matching '{search.searchValue}'</Text>
          ) : search.error ? (
            <Text m={5}>{getNiceErrorMessage(search.error)}</Text>
          ) : null}
        </Box>
      )}
    </Stack>
  )
}

export default InputBottleshop
