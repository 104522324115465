import { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import { Stack, Card, Heading, IconButton, StackProps, Link, Grid } from '@thirstycamel/ui'

export const AvailableOffer = ({
  offer,
  onSelect,
  isLoading,
  redeemable = true,
  ...restProps
}: StackProps & {
  isLoading: boolean
  offer: MyOffersSingleOfferResponse
  onSelect?: () => void
  redeemable?: boolean
}) => {
  let viewLink = '/specials'

  if (offer.productBarcode) {
    viewLink = `/search?barcodes=${offer.productBarcode.join(',')}`
  }

  return (
    <Grid
      gridTemplateColumns="1fr 40px"
      as={Card}
      isInline
      spacing={4}
      bg="gray.50"
      py={3}
      px={4}
      flex="1 1 0"
      justify="space-between"
      flexShrink={0}
      {...restProps}
    >
      <Stack spacing={1} flexShrink={0}>
        <Heading fontSize="xs" textTransform="uppercase" color="pink.500">
          {offer.name}
        </Heading>

        <Heading fontSize="2xs">{offer.shortDescription}</Heading>
      </Stack>

      {redeemable ? (
        <IconButton
          as="div"
          onClick={() => onSelect()}
          icon="check"
          variant="outline"
          isLoading={isLoading}
          variantColor="pink"
          fontSize="lg"
          hasDepth
          flexShrink={0}
        />
      ) : (
        <Link href={viewLink} hrefAs={viewLink}>
          <IconButton
            as="div"
            icon="view"
            variant="outline"
            isLoading={isLoading}
            variantColor="pink"
            fontSize="lg"
            hasDepth
            flexShrink={0}
          />
        </Link>
      )}
    </Grid>
  )
}

export default AvailableOffer
